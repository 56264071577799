// turnstile.js
'use strict';
import loadScriptTag from '../resource/loadScriptTag.js';
import { turnstile } from '../resource/debug.js';
import env from '../resource/env.js';

const log = turnstile.extend('log');

const SCRIPT_ID = 'turnstile-api-script-id';
export const TURNSTILE_CONTAINER_ID = 'turnstile-invisible';
// https://developers.cloudflare.com/turnstile/frequently-asked-questions/#how-long-is-a-turnstile-token-valid-for-before-it-is-rejected-by-siteverify
// const TOKEN_REFRESH_TIMEOUT_MSEC = 300 * 1000 * 0.9;

const isProd = env.NODE_ENV === 'production';

let isTurnstileRemoved = false;

let waitingPromiseResolve;
const generateWaitingPromise = () =>
  new Promise(resolve => {
    waitingPromiseResolve = resolve;
  });
let isGeneratingTokenPromise;
let widgetId;

export const initTurnstile = async ({ sitekey, ...otherParams }) => {
  if (!isProd) return;

  log('initTurnstile', { sitekey, ...otherParams });

  try {
    if (!document.getElementById(SCRIPT_ID)) {
      log('load turnstile script');
      await loadScriptTag({
        id: SCRIPT_ID,
        async: false,
        src: 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit',
      });

      if (window.turnstile)
        await new Promise(resolve => window.turnstile.ready(resolve));
      else throw new Error('turnstile script error');
      log('load turnstile script done');
    }

    if ((window.turnstile && !widgetId) || isTurnstileRemoved) {
      log('render turnstile');
      isTurnstileRemoved = false;
      isGeneratingTokenPromise = generateWaitingPromise();
      widgetId = window.turnstile.render(`#${TURNSTILE_CONTAINER_ID}`, {
        sitekey,
        size: 'invisible',
        callback: token => {
          log('get token success', token);
          waitingPromiseResolve();
        },
        // use our own refresh mechanism to avoid unnecessary history entry
        'refresh-expired': 'never',
        'error-callback': error => {
          log('error', error);
          isGeneratingTokenPromise = null;
          window.turnstile.remove();
          throw new Error(`turnstile error: error-callback ${error}`);
        },
        'unsupported-callback': reason => {
          log('unsupported', reason);
          isGeneratingTokenPromise = null;
          window.turnstile.remove();
          throw new Error(`turnstile error: unsupported-callback ${reason}`);
        },
        ...otherParams,
      });
    }
  } catch (e) {
    log('error', e);
    isGeneratingTokenPromise = null;
    window.turnstile?.remove();
  }
};

export const getTurnstileToken = async () => {
  let token;
  try {
    if (isGeneratingTokenPromise) await isGeneratingTokenPromise;

    token = window.turnstile?.getResponse();

    removeTurnstile();
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return token;
};

// use remove instead of reset
// turnstile.reset will set iframe.src and web add an entry to history
// and then cause some chaos when navigating
const removeTurnstile = async () => {
  log('remove turnstile');

  window.turnstile?.remove();

  isTurnstileRemoved = true;
};

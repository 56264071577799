// handleFetchError.js
import fetchAccessToken, { viaTypes } from '../action/fetchAccessToken.js';
import postMessageToSwagApp from '../action/postMessageToSwagApp.js';
import getMeData from '../selector/getMeData.js';
import {
  getIsOnAndroidWebview,
  getIsOnIOSWebview,
} from '../resource/getUserAgent.js';
import setTurnstileToken from '../action/setTurnstileToken.js';
import getOperationData from '../selector/getOperationData.js';

let isRefreshingToken = false;
let penddingUnauthorizedRequestQueue = [];
let isDoingTurnstile = false;
let pendingRequestsForTurnstile = [];

const isServer = typeof window === 'undefined';

const handleRetryFetch = async ({
  getState,
  fetchOptions,
  fetchUrl,
  shouldIgnoreHeaderAuthorizationOnRetryFetch,
}) => {
  try {
    const accessToken = getMeData(getState(), 'token');
    if (!(fetchOptions.headers instanceof Headers)) {
      fetchOptions.headers = new Headers(fetchOptions.headers || {});
    }
    if (accessToken && !shouldIgnoreHeaderAuthorizationOnRetryFetch) {
      fetchOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return await fetch(fetchUrl.href, fetchOptions);
  } catch (error) {
    throw new Error('Retry fetch with new accessToken failed');
  }
};

const handleRefreshToken = async ({ dispatch, getState }) => {
  isRefreshingToken = true;

  try {
    const refreshToken = getMeData(getState(), 'refreshToken');
    if (!refreshToken) {
      throw new Error(
        JSON.stringify({
          status: 401,
          description: 'No refresh token to fetch new access token',
        })
      );
    }

    await dispatch(
      fetchAccessToken({ refreshToken, via: viaTypes.HANDLE_FETCH_ERROR })
    );

    penddingUnauthorizedRequestQueue.forEach(({ resolve }) => resolve());
  } catch (error) {
    penddingUnauthorizedRequestQueue.forEach(({ reject }) => reject(error));
  } finally {
    penddingUnauthorizedRequestQueue = [];
    isRefreshingToken = false;
  }
};

const handleUnauthorized = async ({
  dispatch,
  getState,
  fetchOptions,
  fetchUrl,
}) => {
  const promise = new Promise((resolve, reject) => {
    penddingUnauthorizedRequestQueue.push({ resolve, reject });
  });
  if (!isRefreshingToken) {
    handleRefreshToken({ dispatch, getState });
  }
  return await promise.then(async () => {
    return await handleRetryFetch({ getState, fetchOptions, fetchUrl });
  });
};

const _handleTurnstileRequired = async ({ dispatch, getState }) => {
  isDoingTurnstile = true;

  try {
    const refreshToken = getMeData(getState(), 'refreshToken');
    if (!refreshToken) {
      throw new Error(
        JSON.stringify({
          status: 401,
          description: 'No refresh token to fetch new access token',
        })
      );
    }

    await dispatch(setTurnstileToken());
    const turnstileToken = getOperationData(getState(), ['turnstile'], 'token');

    await dispatch(
      fetchAccessToken({
        refreshToken,
        turnstileToken,
        via: viaTypes.HANDLE_FETCH_ERROR,
      })
    );

    pendingRequestsForTurnstile.forEach(({ resolve }) => resolve());
  } catch (error) {
    pendingRequestsForTurnstile.forEach(({ reject }) => reject(error));
  } finally {
    pendingRequestsForTurnstile = [];
    isDoingTurnstile = false;
  }
};

const handleTurnstileRequired = async ({
  dispatch,
  getState,
  fetchOptions,
  fetchUrl,
}) => {
  if (getIsOnIOSWebview() || getIsOnAndroidWebview()) {
    dispatch(postMessageToSwagApp({ message: 'reCaptchaRequired' }));
  } else {
    if (!isDoingTurnstile) {
      _handleTurnstileRequired({ dispatch, getState });
    }

    const promise = new Promise((resolve, reject) => {
      pendingRequestsForTurnstile.push({ resolve, reject });
    });

    return await promise.then(async () => {
      return await handleRetryFetch({ getState, fetchOptions, fetchUrl });
    });
  }
};

const handleFetch429Retry = ({
  promiseFunction,
  resetTime,
  timeout,
  onError,
  ...params
}) => {
  async function retry({ remainingTimeout, resetTime }) {
    try {
      const currentTime = Date.now();
      const nextTickTimeout = resetTime - currentTime;

      if (nextTickTimeout <= remainingTimeout && resetTime !== 0) {
        // wait for nextTickTimeout + 1 sec to reduce the 429 error
        await new Promise(resolve =>
          setTimeout(resolve, nextTickTimeout + 1000)
        );
        let response = await promiseFunction();

        if (!response.ok && response.status === 429) {
          return retry({
            remainingTimeout: remainingTimeout - nextTickTimeout,
            resetTime: response.headers.get('x-ratelimit-reset') * 1000,
          });
        } else if (!response.ok) {
          response = await handleFetchError({ response, ...params });
        }

        return response;
      } else onError();
    } catch (_) {
      onError();
    }
  }

  return retry({ remainingTimeout: timeout, resetTime });
};

/**
 * Handle fetch error
 * @kind action
 * @param {object} {response} - error response
 * @param {function} {dispatch} - action dispatch
 * @param {function} {getState} - action getState
 * @param {object} {fetchOptions} - replay fetch options
 * @param {object} {fetchUrl} - replay fetch url
 * @return {Promise} Action promise.
 */
const handleFetchError = async ({ response, ...params }) => {
  const { status, headers, url: urlString } = response;
  const url = new URL(urlString);
  const defaultError = { status, pathname: url.pathname };
  let text = '';
  try {
    text = await response.text();
    // eslint-disable-next-line no-empty
  } catch (_) {}

  let contentType = '';
  try {
    contentType = (headers.get('Content-Type') || '').toLowerCase();
    // eslint-disable-next-line no-empty
  } catch (_) {}

  if (401 === status) {
    if (url.pathname === '/auth/tokens') {
      // means that have tried `fetchAcessToken` but failed,
      // refreshToken might be invalid, return 401 to logout.
      throw new Error(JSON.stringify(defaultError));
    } else if (!isServer) {
      // Try to refresh new acess token
      try {
        return await handleUnauthorized({ ...params });
      } catch (_) {
        throw new Error(JSON.stringify(defaultError));
      }
    }
  }

  if (403 === status && contentType.includes('json')) {
    const responseError = JSON.parse(text);
    const { code } = responseError;
    if (code === 'TURNSTILE_REQUIRED' && !isServer) {
      try {
        return await handleTurnstileRequired({ ...params });
      } catch (_) {
        throw new Error(JSON.stringify({ ...defaultError, code }));
      }
    }
    if (url.pathname === '/me' || url.pathname === '/flight-check') {
      const { code } = responseError;
      const getSentry = (await import('../resource/sentry.js')).getSentry;
      getSentry().then(({ withScope, captureException }) => {
        if (withScope && captureException) {
          const { getState, fetchOptions } = params;
          const accessToken = getMeData(getState(), 'token');
          const refreshToken = getMeData(getState(), 'refreshToken');
          withScope(scope => {
            scope.setExtra('accessToekn', accessToken);
            scope.setExtra('refreshToken', refreshToken);
            scope.setExtra(
              'FOHA', // fetchOptions headers Authorization
              fetchOptions?.headers?.['Authorization'].replace('Bearer ', '')
            );
            scope.setFingerprint(['fetch', 'error']);
            scope.setTag('record', 'handleFetchError');
            captureException(new Error(`Auth 403 ${code}`));
          });
        }
      });
    }
  }

  let textKey = '';
  let rateLimit = {};
  if (429 === status) {
    textKey = 'alert_over_the_rate_limit';
    rateLimit = {
      limit: headers.get('x-ratelimit-limit'),
      remaining: headers.get('x-ratelimit-remaining'),
      resetTime: headers.get('x-ratelimit-reset') * 1000,
    };

    const { getState } = params;
    let retryTimeout =
      getState?.().toJS().remoteConfig?.['RETRY_TIMEOUT_SECONDS'];

    if (retryTimeout && !isServer) {
      const resetTime = headers.get('x-ratelimit-reset') * 1000;

      return handleFetch429Retry({
        promiseFunction: () => handleRetryFetch(params),
        timeout: +retryTimeout * 1000,
        resetTime,
        onError: () => {
          throw new Error(
            JSON.stringify({ ...defaultError, code: textKey, rateLimit })
          );
        },
        ...params,
      });
    }
  } else if (500 <= status && 600 > status) {
    textKey = 'something_went_wrong';
  }
  if (textKey) {
    throw new Error(
      JSON.stringify({ ...defaultError, code: textKey, rateLimit })
    );
  }

  let contentLength = null;
  try {
    contentLength = headers.get('Content-Length');
    // eslint-disable-next-line no-empty
  } catch (_) {}
  if (0 == contentLength) {
    throw new Error(JSON.stringify(defaultError));
  }

  if (contentType.includes('json')) {
    const responseError = JSON.parse(text);
    const { code, message, description } = responseError;
    throw new Error(
      JSON.stringify({ ...defaultError, code, description, message })
    );
  }

  if (text) {
    throw new Error(JSON.stringify({ ...defaultError, text }));
  }
  throw new Error(JSON.stringify(defaultError));
};

export default handleFetchError;

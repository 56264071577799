// sendUserTokenGotLog.js
'use strict';
import trackMixpanel, { EventTypes } from '../resource/mixpanel.js';

const isServer = typeof window === 'undefined';

/**
 * Send user token got log
 * @param {object} payload - payload object.
 * @param {string} payload.token - token.
 * @param {string} payload.type - token type.
 * @param {object} payload.jwtData - decoded jwt data.
 */
const sendUserTokenGotLog = async ({ token, type, jwtData } = {}) => {
  if (isServer || !token) {
    return;
  }
  try {
    let iat = jwtData?.iat;
    let exp = jwtData?.exp;
    let scopes = jwtData?.scopes;
    if (!jwtData) {
      const decode = (await import('jwt-decode')).jwtDecode;
      const data = decode(token) || {};
      scopes = data.scopes;
      iat = data.iat;
      exp = data.exp;
    }
    return trackMixpanel({
      type: EventTypes.USER_TOKEN_GOT,
      payload: {
        token: {
          type,
          scopes,
          iat,
          exp,
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export default sendUserTokenGotLog;

// digestJwtToken.js
'use strict';
import fetchMeSettings from '../action/fetchMeSettings.js';
import fetchMe from '../action/fetchMe.js';

import { MERGE_ME_DATA, PERSIST_NOW } from '../ActionTypes.js';
import getMeData from '../selector/getMeData.js';
import fetchAccessToken, { viaTypes } from './fetchAccessToken.js';
import { setMixpanelViewIdMeData } from '../resource/getMixpanelViewId.js';
import sendUserTokenGotLog from '../resource/sendUserTokenGotLog.js';

const digestingTokens = new Set();

/**
 * Digest jwt token
 * Logout user and redirect to `/` when fetch `/me` fail.
 * @kind action
 * @param {string} {token} - jwt token
 * @return {Promise} Action promise
 */
const digestJwtToken =
  ({ token }) =>
  async (dispatch, getState) => {
    if (!token || digestingTokens.has(token)) {
      return dispatch({ type: '' });
    }

    digestingTokens.add(token);

    const decode = (await import('jwt-decode')).jwtDecode;
    const jwtData = decode(token);
    let meData;
    if (jwtData.version) {
      meData = {
        jwtData, // v2 accessToken
        token,
      };
    } else {
      // v1 auth token, refresh to v2 accessToken and refreshToken
      await dispatch(
        fetchAccessToken({ refreshToken: token, via: viaTypes.JWT_LOGIN })
      );
      const newToken = getMeData(getState(), 'token');
      meData = {
        jwtData: decode(newToken),
        token: newToken,
      };
    }

    const oldTokenIat = getMeData(getState(), 'tokenIat') || 0;
    if (!oldTokenIat || meData?.jwtData.iat > oldTokenIat) {
      // we may trigger many digestJwtToken in one event loop,
      // (ex: render many NeedAuth components on page)
      // but we should only update newer token and jwtData
      dispatch({ type: MERGE_ME_DATA, payload: meData });
      dispatch({ type: PERSIST_NOW });
      sendUserTokenGotLog({
        token: meData.token,
        jwtData: meData.jwtData,
        type: 'accessToken',
      });
    }

    await dispatch(fetchMe({ isForceFetch: true }));

    const meId = getMeData(getState(), 'id');
    const username = getMeData(getState(), 'username');
    if (meId) {
      const [Sentry, { getSentry2Scope }] = await Promise.all([
        import('@sentry/browser'),
        import('../resource/sentry.js'),
      ]);

      const scope = Sentry.getCurrentScope();
      scope.setUser({
        id: meId,
        username: username,
      });

      const sentry2Scope = getSentry2Scope();
      if (sentry2Scope) {
        sentry2Scope.setUser({
          id: meId,
          username: username,
        });
      }

      setMixpanelViewIdMeData({
        meId,
        meUsername: username,
      });

      // track event v2 need preference
      dispatch(fetchMeSettings());
    }
    digestingTokens.delete(token);
    return;
  };

export default digestJwtToken;
